
import { Vue, Component } from 'vue-property-decorator'
import Utils from 'tradingmate-modules/src/Utils'
import EmailLoggedModel from 'tradingmate-modules/src/models/api/admin/emails/logged/EmailLoggedModel'
import { Services } from 'tradingmate-modules/src/services'

@Component({
  components: {
  }
})

export default class LoggedEmailDetails extends Vue {
  private loggedEmail: EmailLoggedModel | null = null
  private loggedEmailId: string = this.$route.params.id
  private loading = false
  private isoToDisplayDate = Utils.isoToDisplayDate
  private isoToDisplayTime = Utils.isoToDisplayTime

  mounted (): void {
    this.getLoggedEmail()
  }

  getLoggedEmail (): void {
    this.loading = true
    if (!this.loggedEmailId) return
    Services.API.Admin.Emails.GetLoggedEmail(this.loggedEmailId)
      .then((data) => { this.loggedEmail = data })
      .finally(() => { this.loading = false })
  }
}

